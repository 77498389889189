import sortBy from 'lodash.sortby';

import { EntityResult, getEntityTypeAndTags, PROGRAM_NAME_TAG_REGEXP } from '@shared/utils/entity';
import { getProjectUrl } from '@shared/utils/project';
import { BaseProject, BaseProjectQuery, Entity, Tags, Status, DependantProjects } from 'src/graphql/types';
import { getOldestProject, Project, Initiative } from '@program/children/utils/program';
import { getProgramLinkByKey } from '@shared/CreationFlow/utils/link';
import { ParentProgram, ParentProgramNode } from '@queries/FindParentProjectsByTag';
import { Tag } from '@queries/GetProjectPageItems';

export const getInitiativeNameTag = (initiativeTagName: string, { type, initiativeTags }: EntityResult) => {
  if (type !== Entity.INITIATIVE) {
    return;
  }

  return initiativeTags.find(({ name }) => name === initiativeTagName);
};

export const getInitiativeNameTagsFromProject = (initiativeTagName: string, { type, initiativeTags }: EntityResult) => {
  if (type !== Entity.PROJECT) {
    return;
  }

  return initiativeTags.filter(({ name }) => name === initiativeTagName);
};

export const getInitiative = <T extends BaseProject>({
  initiativeTagName,
  initiativeQueryData,
  projectsQueryData,
  isVerifiedPage,
}: {
  initiativeTagName: string;
  initiativeQueryData?: BaseProjectQuery<T>;
  projectsQueryData?: BaseProjectQuery<T>;
  isVerifiedPage?: boolean;
}) => {
  const initiativeData = initiativeQueryData?.projectTql.edges.flatMap(({ node }) => node) || [];
  const projectsData = projectsQueryData?.projectTql.edges.flatMap(({ node }) => node) || [];

  if (!initiativeData.length) {
    return;
  }

  const initiativeProjects: Project<T>[] = [];

  const initiative = initiativeQueryData?.projectTql.edges.reduce<Initiative<T & DependantProjects> | undefined>(
    (acc, { node }) => {
      const entityResult = getEntityTypeAndTags(node);
      const initiativeTag = getInitiativeNameTag(initiativeTagName, entityResult);

      if (initiativeTag) {
        acc = { initiativeTag, ...getOldestProject(node, acc), isDuplicated: !!acc, projects: [], link: '' };
      } else if (isVerifiedPage) {
        acc = {
          initiativeTag: {} as Tag,
          ...getOldestProject(node, acc),
          isDuplicated: !!acc,
          projects: [],
          link: '',
        };
      }

      return acc;
    },
    undefined
  );

  projectsData.forEach((project) => {
    const entityResult = getEntityTypeAndTags(project);

    const projectInitiativeTags = getInitiativeNameTagsFromProject(initiativeTagName, entityResult);

    if (isVerifiedPage) {
      initiativeProjects.push({ ...project, initiativeTags: [] });
    }

    if (projectInitiativeTags?.length) {
      return initiativeProjects.push({ ...project, initiativeTags: projectInitiativeTags });
    }
  });

  return (
    initiative && {
      ...initiative,
      link: getProjectUrl({
        isDuplicated: initiative.isDuplicated,
        key: initiative.key,
        uuid: initiative.initiativeTag.uuid,
        tags: initiative.tags,
        isProgram: false,
      }),
      projects: initiativeProjects,
    }
  );
};

export const generateInitiativePageTql = (
  initiativeNameTagName: string,
  programTag?: string,
  projectsKeys?: string
) => ({
  initiativesQ: `(archived = false) AND (label = ${Tags.initiative} AND label = ${initiativeNameTagName})`,
  initiativesQByKey: `(archived = false) AND (key = ${initiativeNameTagName})`,
  initiativeWithProgramQ: `(archived = false) AND (label = ${Tags.initiative} AND label = ${initiativeNameTagName} AND label = ${programTag})`,
  projectsQ: `(archived = false) AND (label = ${initiativeNameTagName})`,
  projectsQByKeys: `(archived = false) AND key IN (${projectsKeys})`,
  doneProjectsQ: `(archived = false) AND (label = ${initiativeNameTagName} AND status_phase = ${Status.DONE})`,
  doneProjectsQByKeys: `(archived = false) AND key IN (${projectsKeys}) AND (status_phase = ${Status.DONE})`,
});

export const getParentProgram = (programs: ParentProgramNode[], parentProgramsTags: string[]) => {
  const filteredPrograms = programs.filter((program) => {
    const programTags = program.node.tags.edges.filter((pgm) => PROGRAM_NAME_TAG_REGEXP.test(pgm.node.name));

    return programTags.every((tag) => parentProgramsTags.includes(tag.node.name));
  });

  return filteredPrograms
    .map((program) => {
      const findProgram = program.node.tags.edges.find((program) => PROGRAM_NAME_TAG_REGEXP.test(program.node.name));

      const creationDate = findProgram?.node.creationDate || '';

      return {
        name: program.node.name,
        link: getProgramLinkByKey(program.node.key),
        creationDate,
      };
    })
    .sort((pgm, nexProgram) => {
      if (new Date(pgm.creationDate).getTime() > new Date(nexProgram.creationDate).getTime()) {
        return 1;
      }
      if (pgm.name.toLowerCase() < nexProgram.name.toLowerCase()) {
        return -1;
      }

      return 0;
    });
};

export const getAssetsParentProgram = (programs: ParentProgram[]) =>
  sortBy(
    programs?.map((program) => ({
      name: program.name,
      link: getProgramLinkByKey(program.key),
    })),
    (program) => program.name.toLowerCase()
  );

export const checkIsVerifiedPage = (url: string) => !/initiative/.test(url);
