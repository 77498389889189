import styled from 'styled-components';
import { h200, h700 } from '@atlaskit/theme/typography';
import { N800 } from '@atlaskit/theme/colors';

import { Card } from '@tc/Card/styles';
import { StatusChangeCounterType } from '@department/children/utils/weeklySummary';

export const ItemContainer = styled(Card)<{
  color?: string;
  isClickable: boolean;
  isTransparent: boolean;
  isUpdateDetailCard?: boolean;
}>`
  padding: 12px 0 12px 8px;
  flex: 1 1 100px;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  opacity: ${({ isTransparent }) => (isTransparent ? 0.4 : 1)};
  margin: ${({ isUpdateDetailCard }) => (isUpdateDetailCard ? '0 !important' : 'inherit')};

  &:hover {
    box-shadow: ${({ isUpdateDetailCard, isClickable }) =>
      isUpdateDetailCard && !isClickable
        ? 'rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 31%) 0px 0px 1px 0px'
        : 'rgba(9, 30, 66, 0.25) 0px 3px 3px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px'};
  }
  > div {
    display: flex;
    align-items: flex-end;
  }

  h2 {
    ${h700}
    margin-top: 0px;
    color: ${(props) => props.color};
  }
`;

export const ItemHeading = styled.h4`
  ${h200}
  color: ${N800};
`;

export interface ItemProps {
  title: string;
  color: string;
  id: string;
  onClick: () => void;
  statusChangeSummary: StatusChangeCounterType;
  isClickable?: boolean;
  isTransparent: boolean;
}

export const Item = (props: ItemProps) => {
  const {
    color,
    onClick,
    statusChangeSummary: { in: inProjects, out, noChange },
    isClickable: isClickableProp,
    isTransparent,
  } = props;

  const currentValue = noChange + inProjects;
  const showChanges = inProjects || out;
  const isClickable = isClickableProp || !!noChange || !!inProjects || !!out;

  const incomingProjects = inProjects ? `${inProjects} in` : '';
  const slash = inProjects && out ? ' / ' : '';
  const outProjects = out ? `${out} out` : '';

  return (
    <ItemContainer
      data-testid={`${props.id}-weekly-summary-item`}
      color={color}
      onClick={onClick}
      isClickable={isClickable}
      isTransparent={isTransparent}
    >
      <ItemHeading>{props.title}</ItemHeading>
      <div>
        <h2>{currentValue}</h2>
      </div>
      {showChanges ? <small>{`${incomingProjects}${slash}${outProjects}`}</small> : <small>No change</small>}
    </ItemContainer>
  );
};
