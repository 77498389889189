import { isBefore } from 'date-fns';
import uniqBy from 'lodash.uniqby';
import sortBy from 'lodash.sortby';
import orderBy from 'lodash.orderby';
import { OptionType } from '@atlaskit/select';
import { GroupTypeBase } from 'react-select';

import { BaseProject, BaseProjectQuery, DependantProjects, Entity, Status, Tags } from 'src/graphql/types';
import { Project as GqlProject, Tag, ProjectEdges, ProjectNode } from '@queries/GetProjectPageItems';
import { isValidDepartmentTag, getProjectUrl } from '@shared/utils/project';
import {
  EntityResult,
  getEntityTypeAndTags,
  INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP,
  PROGRAM_NAME_TAG_REGEXP,
} from '@shared/utils/entity';
import { getProjects } from '@shared/utils/initiative';
import {
  DEPARTMENT_NAME_TAG_REGEXP,
  DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP,
  getDeptName,
} from '@department/children/utils/department';
import { Department } from '@store/departmentStore';
import { getConfig } from 'src/config';
import { ProjectDependencyRelationship } from 'src/graphql/generated/graphql';
import { Commit } from 'src/store/commitsStore';
import { ReportItemType } from 'src/pages/report/children/utils';

export type Project<T = GqlProject> = T & {
  initiativeTags?: Tag[];
};

export type Initiative<T = GqlProject> = T & {
  link: string;
  initiativeTag: Tag;
  projects: Project<T>[];
  isDuplicated: boolean;
  filtered?: boolean;
  isVerified?: boolean;
  children?: Initiative<T>[];
};

export type ReportCommit = Omit<Commit, 'title'> & { summary: string } & { children: Commit[] };

export type MilestoneReport<T = Omit<GqlProject, 'status'>> = T & {
  link: string;
  initiativeTag: Tag;
  projects: Project<T>[];
  isDuplicated: boolean;
  filtered?: boolean;
  isVerified?: boolean;
  summary: string;
  isNewJiraIssue?: boolean;
  type: ReportItemType;
  upperLevelId: string;
  status: Commit['status'];
  children?: ReportCommit[];
};

export type Program<T = GqlProject> = T & {
  link: string;
  programTag: Tag;
  initiatives: Initiative<T>[];
  projects: T[];
  isDuplicated: boolean;
  isVerified?: boolean;
};

export type TagUuidToInitiativeMap<T = GqlProject> = {
  [key: string]: Initiative<T>;
};

export type ProjectsByParent<T = GqlProject> = {
  belongToInitiative: Project<T>[];
  belongToProgram: Project<T>[];
};

export type InitiativeTagUuidToEntityMap<T> = {
  [key: string]: T;
};

export type DepartmentOption = OptionType & { pillars?: OptionType[] };

const getProgramNameTag = (programTagName: string, { type, programTags }: EntityResult) => {
  if (type !== Entity.PROGRAM) {
    return;
  }

  const [programTag] = programTags;

  return programTag.name === programTagName ? programTag : undefined;
};

const getInitiativeNameTag = ({ type, initiativeTags }: EntityResult) => {
  if (type !== Entity.INITIATIVE) {
    return;
  }

  const tags = initiativeTags.filter(({ name }) => INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP.test(name));

  return tags.length === 1 ? tags[0] : undefined;
};

const getInitiativeNameTagsFromProject = (programTagName: string, { type, initiativeTags }: EntityResult) => {
  if (type !== Entity.PROJECT) {
    return;
  }

  const tags = initiativeTags.filter(({ name }) => INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP.test(name));

  return tags.length ? tags : undefined;
};

export const isDirectProgramProject = (programTagName: string, { type, programTags }: EntityResult) =>
  type === Entity.PROJECT && !!programTags.find(({ name }) => name === programTagName);

export const getOldestProject = <T extends BaseProject>(comparedProject: T, project: T | undefined) => {
  if (!project) {
    return comparedProject;
  }

  return isBefore(new Date(comparedProject.creationDate), new Date(project.creationDate)) ? comparedProject : project;
};

export const getInitialProgram = <T extends BaseProject>(programTagName: string, queryResult?: BaseProjectQuery<T>) =>
  queryResult?.projectTql.edges.reduce<Program<T> | undefined>((acc, { node }) => {
    acc = {
      ...node,
      programTag: node.tags?.edges.filter(({ node }) => PROGRAM_NAME_TAG_REGEXP.test(node.name))[0]?.node,
      isDuplicated: false,
      initiatives: [],
      projects: [],
      link: `${getConfig().teamCentralUrl}/project/${node?.key}/updates`,
    };

    return acc;
  }, undefined);

export const getProgram = <T extends BaseProject>(
  programTagName: string,
  queryResult?: BaseProjectQuery<T>,
  isVerifiedPage?: boolean
): Program<T & DependantProjects> | undefined => {
  const program = queryResult?.projectTql.edges.reduce<Program<T & DependantProjects> | undefined>((acc, { node }) => {
    const entityResult = getEntityTypeAndTags(node);
    const programTag = getProgramNameTag(programTagName, entityResult);

    if (programTag) {
      acc = {
        programTag,
        ...getOldestProject(node, acc),
        isDuplicated: !!acc,
        initiatives: [],
        projects: [],
        link: '',
      };
    } else if (isVerifiedPage) {
      acc = {
        programTag: {} as Tag,
        ...getOldestProject(node, acc),
        isDuplicated: !!acc,
        initiatives: [],
        projects: [],
        link: '',
      };
    }

    return acc;
  }, undefined);

  return (
    program && {
      ...program,
      link: getProjectUrl({
        isDuplicated: program.isDuplicated,
        key: program.key,
        uuid: program?.programTag?.uuid,
        tags: program.tags,
        isProgram: true,
      }),
    }
  );
};

export const getTagUuidToInitiativeMap = <T extends BaseProject>(
  programTagName: string,
  queryResult?: BaseProjectQuery<T>
) =>
  queryResult?.projectTql.edges.reduce<TagUuidToInitiativeMap<T & DependantProjects>>((acc, { node }) => {
    const entityResult = getEntityTypeAndTags(node);
    const initiativeTag = getInitiativeNameTag(entityResult);

    if (initiativeTag) {
      acc[initiativeTag.uuid] = {
        initiativeTag,
        ...getOldestProject(node, acc[initiativeTag.uuid]),
        projects: [],
        link: getGlobalStateInitiativeLink(programTagName, initiativeTag),
        isDuplicated: !!acc[initiativeTag.uuid],
      };
    }

    return acc;
  }, {});

export const getMilestones = <T extends BaseProject>(milestone?: BaseProjectQuery<T>) =>
  milestone?.projectTql.edges.reduce<Initiative<T & DependantProjects>[]>((acc, { node }, i) => {
    acc.push({
      initiativeTag: {} as Tag,
      ...getOldestProject(node, acc[i]),
      projects: [],
      link: `/milestone/${node.key}`,
      isDuplicated: false,
    });

    return acc;
  }, []);

export const getProjectsByParent = <T extends BaseProject>(
  programTagName: string,
  queryResult?: BaseProjectQuery<T>,
  initiativeProjects?: (T & DependantProjects)[]
) => {
  let projects = queryResult?.projectTql.edges.map(({ node }) => node);

  projects = initiativeProjects && projects && uniqBy([...projects, ...initiativeProjects], 'id');

  return getProjectsByOwnership(programTagName, projects);
};

export const getProjectsByOwnership = <T extends BaseProject>(programTagName: string, projects?: T[]) =>
  projects?.reduce<ProjectsByParent<T>>(
    (acc, node) => {
      const entityResult = getEntityTypeAndTags(node);

      const projectInitiativeTags = getInitiativeNameTagsFromProject(programTagName, entityResult);

      if (projectInitiativeTags) {
        acc.belongToInitiative.push({ ...node, initiativeTags: projectInitiativeTags });
      }

      if (isDirectProgramProject(programTagName, entityResult)) {
        acc.belongToProgram.push({ ...node, initiativeTags: projectInitiativeTags || [] });
      }

      return acc;
    },
    { belongToProgram: [], belongToInitiative: [] }
  );

export const mergeProjectUpdates = (initUpdates: ProjectEdges, updates: ProjectEdges) =>
  initUpdates?.map((project: ProjectNode) => ({
    node: {
      ...project.node,
      updates: {
        edges: orderBy(
          uniqBy(
            [
              ...project.node.updates.edges,
              ...(updates?.find(({ node }: ProjectNode) => node.id === project.node.id)?.node.updates.edges || []),
            ],
            'node.id'
          ),
          'node.creationDate'
        ).reverse(),
      },
    },
  })) as ProjectEdges;

export const mergeProjects = (updates: ProjectEdges) =>
  Object.values(
    updates.reduce((acc: { [key: string]: ProjectNode }, value: ProjectNode) => {
      acc[value.node.id] = {
        node: {
          ...(acc[value.node.id]?.node || {}),
          ...value.node,
          updates: {
            edges: orderBy(
              uniqBy([...(acc[value.node.id]?.node.updates?.edges || []), ...value?.node.updates?.edges], 'node.id'),
              'node.creationDate'
            ).reverse(),
          },
        },
      };

      return acc;
    }, {})
  ) as ProjectEdges;

export const gatherProgram = <T extends BaseProject>(
  program?: Program<T>,
  tagUuidToInitiativeMap?: TagUuidToInitiativeMap<T>,
  projectsByParent?: ProjectsByParent<T>
): Program<T> | undefined => {
  if (!program) {
    return;
  }

  const tagIdToProjectsMap = projectsByParent?.belongToInitiative.reduce<{ [key: string]: Project<T>[] }>(
    (acc, project) => {
      project?.initiativeTags?.forEach(({ uuid }) => {
        acc[uuid] = [...(acc[uuid] || []), project];
      });

      return acc;
    },
    {}
  );

  let initiatives = Object.values(tagUuidToInitiativeMap || {});
  // match projects to parent initiatives if projects are received

  if (projectsByParent?.belongToInitiative) {
    initiatives = initiatives.map((initiative) => ({
      ...initiative,
      projects: tagIdToProjectsMap?.[initiative.initiativeTag?.uuid] || [],
    }));
  }

  return {
    ...program,
    initiatives,
    projects:
      projectsByParent?.belongToProgram.filter(({ initiativeTags }) =>
        initiativeTags?.every(({ uuid }) => !tagUuidToInitiativeMap || !tagUuidToInitiativeMap[uuid])
      ) || [],
  };
};

export const gatherUpdatedProgram = <T extends BaseProject>(
  program?: Program<T & DependantProjects>,
  milestones?: Initiative<T & DependantProjects>[],
  projects?: BaseProjectQuery<T & DependantProjects>
): Program<T> | undefined => {
  if (!program) {
    return;
  }
  const programProjects: (T & DependantProjects)[] = [];
  const milestonesWithProjects: Initiative<T & DependantProjects>[] = [];

  program.dependencies.edges
    .filter((dependency) => dependency.node.linkType === ProjectDependencyRelationship.DependsOn)
    .forEach((dependency) => {
      projects?.projectTql.edges.forEach((project) => {
        if (dependency.node.outgoingProject.key === project.node.key) {
          programProjects.push(project.node);
        }
      });
    });

  milestones?.forEach((milestone) => {
    milestone.dependencies.edges
      .filter((dependency) => dependency.node.linkType === ProjectDependencyRelationship.DependsOn)
      .forEach((dependency) => {
        projects?.projectTql.edges.forEach((project) => {
          if (dependency.node.outgoingProject.key === project.node.key) {
            milestone.projects.push(project.node);
          }
          milestone.projects = [...uniqBy(milestone.projects, 'id')];
        });
      });
    milestonesWithProjects.push(milestone);
  });

  return {
    ...program,
    initiatives: milestonesWithProjects,
    projects: programProjects,
  };
};

export const getProgramProjectsAndInitiatives = <T extends BaseProject>(
  program: Program<T>
): Array<Initiative<T> | T> =>
  uniqBy(
    program.initiatives.reduce<Array<Initiative<T> | T>>(
      (acc, initiative) => [...acc, ...(initiative.filtered ? [] : [initiative]), ...initiative.projects],
      [...program.projects]
    ),
    'id'
  );

const getDepartmentTagNames = (tags: GqlProject['tags']) =>
  tags.edges.reduce<string[]>((acc, { node: { name } }) => {
    if (DEPARTMENT_NAME_TAG_REGEXP.test(name) || DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP.test(name)) {
      acc.push(name);
    }

    return acc;
  }, []);

const getUniqueDepartmentOptions = (departmentTagNames: string[]) => {
  const uniqueLabels = uniqBy(departmentTagNames, (label) => label);

  const deptNameToOptionMap = uniqueLabels.reduce<{ [key: string]: GroupTypeBase<OptionType> | OptionType }>(
    (acc, label) => {
      const [prefix, dept, pillar] = label.split('--');
      const departmentLabel = `${prefix}--${dept}`;

      if (pillar) {
        acc[departmentLabel] = {
          ...(acc[departmentLabel]
            ? acc[departmentLabel]
            : { label: getDeptName(departmentLabel), value: departmentLabel }),
          options: sortBy(
            uniqBy([...(acc[departmentLabel]?.options || []), { label: getDeptName(label), value: label }], 'value'),
            'label'
          ),
        };
      } else {
        acc[label] = { ...(acc[label] ? acc[label] : { label: getDeptName(label), value: label, options: [] }) };
      }

      return acc;
    },
    {}
  );

  return Object.values(deptNameToOptionMap);
};

export const flattenDepartmentOptions = (departmentOptions: Array<GroupTypeBase<OptionType> | OptionType>) =>
  departmentOptions.reduce<Array<DepartmentOption>>((acc, { options, label, value }) => {
    acc.push({ label, value, pillars: options });

    if (options) {
      acc.push(...options);
    }

    return acc;
  }, []);

export const getDepartmentOptions = (program: Program | Initiative, departments: Department[]) => {
  if (!departments.length) {
    return [];
  }

  const departmentLabels = departments.map(({ label }) => label);

  const departmentTagNames = [
    ...getDepartmentTagNames(program.tags),
    ...getProjects(program).flatMap(({ tags }) => getDepartmentTagNames(tags)),
  ].filter((tag) => isValidDepartmentTag(departmentLabels, tag));

  return sortBy(getUniqueDepartmentOptions(departmentTagNames), 'label');
};

export const getProgramPageProjectTqlQueries = (
  programTagName: string,
  verifiedInitiativesKeys = [] as string[],
  directProjectsKeys: string[] = []
) => ({
  programQ: `(archived = false) AND (label = ${Tags.program} AND label = ${programTagName})`,
  programQByKey: `(archived = false) AND key = ${programTagName})`,
  initiativeQ: `(archived = false) AND (label = ${Tags.initiative} AND label = ${programTagName})`,
  initiativeQAssets: `(archived = false) AND (key in ("${verifiedInitiativesKeys.join('", "')}"))`,

  doneInitiativeQ: `((archived = false) AND (label = ${Tags.initiative} AND label = ${programTagName} AND status_phase = ${Status.DONE}))`,
  doneInitiativeQAssets: `(archived = false) AND (key in ("${verifiedInitiativesKeys.join(
    '", "'
  )}")) AND (status_phase = ${Status.DONE}))`,

  projectQ: `(archived = false) AND (label = ${Tags.project} AND label = ${programTagName})`,
  projectQByKeys: `(archived = false) AND (key in ("${directProjectsKeys.join('", "')}"))`,

  doneProjectsQ: `((archived = false) AND (label = ${Tags.project} AND label = ${programTagName} AND status_phase = ${Status.DONE}))`,
  doneProjectsQByKeys: `((archived = false) AND (key in ("${directProjectsKeys.join('", "')}")) AND (status_phase = ${
    Status.DONE
  }))`,
});

const getGlobalStateInitiativeLink = (programTagName: string, initiativeTag: Tag) =>
  `/initiative/${initiativeTag.name.split('--').pop()}`;

export const getSortedInitiatives = (initiatives: Initiative[], keys: string[]) =>
  sortBy(initiatives, (item) => (keys.indexOf(item.key) !== -1 ? keys.indexOf(item.key) : keys.length));
